<i18n locale="th" lang="yaml" >
sign_modal.title: "กรุณาลงลายมือชื่อ"
sign_modal.name.and.surname: "ชื่อ-สกุล"
sign_modal.name.and.surname.placeholder: "กรุณากรอกชื่อและนามสกุลเต็ม"
sign_modal.resign: "ลบลายมือชื่อ"
sign_modal.warning: "กรุณาลงลายมือชื่อและกรอกชื่อ-สกุลให้ครบถ้วน"
</i18n>
<template>
	<a-modal centered :visible="visible" :title="$t('sign_modal.title')" :mask-closable="false" @cancel="handleCancel" @ok="handleSave">
		<div class="canvas-container">
			<canvas
				id="sign"
				ref="sign"
        width="400"
        height="130"
				tabindex="1"
				@mousedown="mousedown"
				@mousemove="mousemove"
				@mouseup="sign = false"
				@mouseout="sign = false"
				@touchstart="touchstart"
				@touchmove="touchmove"/>
			<div>
				<label>{{ $t('sign_modal.name.and.surname') }} : </label>
				<a-input
					v-model="fullName"
					allow-clear
					class="input-block"
					:placeholder="$t('sign_modal.name.and.surname.placeholder')"/>
			</div>
			<div>
				<a-button class="button" icon="delete" type="danger" @click="clear">{{ $t('sign_modal.resign') }}</a-button>
			</div>
		</div>
	</a-modal>
</template>
<script>
import DeviceMixin from "@mixins/DeviceMixin.vue"

export default {
	mixins : [DeviceMixin] ,
	data() {
		return {
			visible: false,
			ctx: null,
			sign: false,
			prevX: 0,
			prevY: 0,
			fullName: null,
		}
	},
	methods: {
		touchstart(e) {
			const rect = e.target.getBoundingClientRect()
			this.$refs.sign.focus()
			this.sign = true
			this.prevX = e.targetTouches[0].pageX - rect.left
			this.prevY = e.targetTouches[0].pageY - rect.top
		},
		touchmove(e) {
			if (this.sign) {
				this.setLineProps()
				const rect = e.target.getBoundingClientRect()
				const currX = e.targetTouches[0].pageX - rect.left
				const currY = e.targetTouches[0].pageY - rect.top
				this.draw(this.prevX, this.prevY, currX, currY)
				this.prevX = currX
				this.prevY = currY
			}
		},
		mousedown($event) {
			if (this.isDesktop) {
				this.sign = true
				this.prevX = $event.offsetX
				this.prevY = $event.offsetY
			}
		},
		mousemove($event) {
			if (this.sign && this.isDesktop) {
				this.setLineProps()
				const currX = $event.offsetX
				const currY = $event.offsetY
				this.draw(this.prevX, this.prevY, currX, currY)
				this.prevX = currX
				this.prevY = currY
			}
		},
		setLineProps() {
			this.ctx = this.$refs.sign.getContext('2d')
			this.ctx.strokeStyle = 'black'
			this.ctx.lineWidth = 2
			this.ctx.lineCap = 'round'
			this.ctx.lineJoin = 'round'
		},
		draw(depX, depY, destX, destY) {
			this.ctx.beginPath()
			this.ctx.moveTo(depX, depY)
			this.ctx.lineTo(destX, destY)
			this.ctx.closePath()
			this.ctx.stroke()
		},
		openSignModal() {
			this.visible = true
		},
		closeModal() {
			this.visible = false
		},
		handleCancel() {
			this.closeModal()
		},
		handleSave() {
			if (!this.ctx || !this.fullName) {
				this.$message.error(this.$t('sign_modal.warning'))
			} else {
				const img = this.$refs.sign.toDataURL('image/png')
				const name = this.fullName
				this.$emit('save', { img, name })
				this.visible = false
			}
		},
		clear() {
			const canvas = this.ctx
			if (canvas) {
				canvas.clearRect(0, 0, this.$refs.sign.width, this.$refs.sign.height)
				this.ctx = null
			}
		},
	},
}
</script>
<style scoped>
canvas {
	border: 0.5px solid grey;
	display: inline;
	background-color: white;
	cursor: crosshair;
  overscroll-behavior: none;
}
.button {
	margin-top: 5px;
	margin-left: auto;
	margin-right: 0;
}
.canvas-container {
	flex-direction: column;
	width: 100%;
	text-align: center;
}
.input-block {
	display: inline-block;
	max-width: 550px;
	margin-bottom: 10px;
	.mobile & {
		display: block;
		margin-bottom: 4px;
		margin-right: 0;
	}
}
</style>
