export const CLAIM_REQUEST_STATUS = {
	DRAFT: 'draft',
	REQUEST: 'request',
	APPEAL: 'appeal',
	APPROVED: 'approved',
	REJECTED: 'rejected',
	DRAFT_APPEAL: 'draft_appeal',
}

export function claimInfo(component, item) {
	if (item.partSku === '-') return ''

	return item.requestClaimNum > 0 ? component.$t('service_cost_estimate.claim_request').concat(component.$t('service_cost_estimate.claim_amount',{ requestClaimNum: item.requestClaimNum })) : component.$t('service_cost_estimate.no_claim_request')
}
