<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml">
	onfield_cost_estimate.page.title : "ใบเสนอราคา"
	onfield_cost_estimate.page.description : "ขั้นตอนที่ 6/8"
	onfield_cost_estimate.footer.signature : "......................................"
	onfield_cost_estimate.consent.signature.with.description : "ลายมือชื่อ............................."
	onfield_cost_estimate.consent.description : "ลายมือชื่อ"
	onfield_cost_estimate.sign_date : "วันที่: {date}"
	onfield_cost_estimate.sign_warning : "กรุณาลงลายมือชื่อผู้เข้ารับบริการ, ผู้เสนอราคา, และหนังสือให้ความยินยอมให้ครบถ้วนก่อนดำเนินการต่อ"

	onfield_cost_estimate.action.update.confirm.title : "กรุณายืนยันเพื่อดำเนินการต่อไป"
	onfield_cost_estimate.action.update.confirm.message : "ต้องการดำเนินการไปยังขั้นตอนถัดไปหรือไม่?"
	onfield_cost_estimate.success : "ดำเนินการเรียบร้อย"

	action.rollback.page : "แก้ไขรายการอะไหล่/งานซ่อม"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			class="main-header"
			:title="$t('onfield_cost_estimate.page.title')"
			:description="$t('onfield_cost_estimate.page.description')"/>

		<div class="page-padding background">
			<a-spin wrapper-class-name="page-loading" :spinning="loading" size="large" :tip="$t('service_price_list.pageLoading.tip')">
				<div id="print-service-price-list">
					<div class="service-detail-container">
						<div class="service-col">
							<label>{{ $t('service_price_list.header.company_info') }}</label>
							<div class="service-val">
								<div class="service-val-row">
									{{ displayCompany.name }}
								</div>
								<div class="service-val-row">
									{{
										(displayCompany.address ? displayCompany.address + ' ' : '') +
										displayCompany.province +
										' ' +
										displayCompany.postal_code
									}}
								</div>
								<div v-if="displayCompany.contact_phone" class="service-val-row">
									<PhoneLink :view-only="true" :value="displayCompany.contact_phone" />
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service_price_list.header.drone') }}</label>
							<div v-if="service.droneData" class="service-val">
								<div class="service-val-row">
									{{ $t('service_price_list.header.drone.serial', { serial: service.droneData.drone.serial_no }) }}
								</div>
								<div class="service-val-row">
									{{ $t('service_price_list.header.drone.pixhawk', { pixhawk: service.droneData.drone.pixhawk_serial_no }) }}
								</div>
								<div class="service-val-row">
									{{ $t('service_price_list.header.drone.model', { name: service.droneData.model.name }) }}
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.customer') }}</label>
							<div class="service-val">
								<div class="service-val-row">
									{{ service.customer_name }}
								</div>
								<div class="service-val-row">
									<PhoneLink :view-only="true" :value="service.customer_phone" />
									<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + service.customer_province }}</span>
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.open_date') }}</label>
							<div class="service-val">{{ service.open_date }}</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.technician') }}</label>
							<div class="service-val">
								<div class="service-val-row">
									<span v-if="service.technicianData">
										{{ service.technicianData.user.full_name }}
									</span>
									<span v-else>
										{{ $t('common.unspecify') }}
									</span>
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.open_by') }}</label>
							<div v-if="service.openByData" class="service-val">
								{{ service.openByData.user.full_name }}
							</div>
						</div>
					</div>

					<a-table bordered :row-key="(record) => record.key" :data-source="items" :pagination="false" size="small">
						<a-table-column :title="$t('common.table.column.no')" :width="10" align="center">
							<template slot-scope="record">
								<div>{{ record.sequence }}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.sku')" :width="80">
							<template slot-scope="record">
								<div>{{ record.partSku }}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.name')" :width="190">
							<template slot-scope="record">
								<div>{{ record.description }}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.num')" align="center" :width="40">
							<template slot-scope="record">
								<div>{{ record.partNumChange }}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.pricePerUnit')" align="right" :width="50">
							<template slot-scope="record">
								<div>{{ record.pricePerUnitDisplay }}</div>
							</template>
						</a-table-column>
						<a-table-column  :title="$t('service_price_list.field.price')" align="right" :width="60">
							<template slot-scope="record">
								<div style="white-space: pre-line">{{ record.priceDisplay }}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.claim')" align="right" :width="40">
							<template slot-scope="record">
								<div>{{ record.claimInfo }}</div>
							</template>
						</a-table-column>
					</a-table>
					<div class="last-page">
						<div class="total-section">
							<div class="total-section-column">
								<div class="total-section-row">
									<div class="total-section-unit">บาท</div>
									<div :class="{ 'total-section-price': hasClaimPrice, 'total-section-price-claim': !hasClaimPrice }">
										{{ totalPriceBeforeVat }}
									</div>
									<div v-if="hasClaimPrice" class="total-section-dash">-</div>
									<div v-if="hasClaimPrice" class="total-section-price-claim">
										{{ totalPriceBeforeVatMin }}
									</div>
									<div class="total-section-description">ราคาประเมินก่อนรวม vat (7%)</div>
								</div>
								<div class="total-section-row">
									<div class="total-section-unit">บาท</div>
									<div :class="{ 'total-section-price': hasClaimPrice, 'total-section-price-claim': !hasClaimPrice }">
										{{ totalVat }}
									</div>
									<div v-if="hasClaimPrice" class="total-section-dash">-</div>
									<div v-if="hasClaimPrice" class="total-section-price-claim">
										{{ totalVatMin }}
									</div>
									<div class="total-section-description">vat (7%)</div>
								</div>
								<div class="total-section-row">
									<div class="total-section-unit text-bold">บาท</div>
									<div :class="{ 'total-section-price': hasClaimPrice, 'total-section-price-claim': !hasClaimPrice, 'text-bold': true }">
										{{ totalPriceAfterVat }}
									</div>
									<div v-if="hasClaimPrice" class="total-section-dash text-bold">-</div>
									<div v-if="hasClaimPrice" class="total-section-price-claim text-bold">{{ totalPriceAfterVatMin }}</div>
									<div class="total-section-description text-bold">ราคาประเมินรวม vat (7%)</div>
								</div>
							</div>
						</div>
						<div v-if="hasClaimPrice" v-html="$t('servcie_price_list.footer.claim_info')" />
						<div v-html="$t('servcie_price_list.footer.price_info')" />
						<div v-html="$t('servcie_price_list.footer.consent.period_info')" />
						<div v-html="$t('servcie_price_list.footer.consent.change_price_info')" />
						<div v-html="$t('servcie_price_list.footer.consent.insurace_info')" />
						<a-radio-group :default-value="agreement" class="consent-group" @change="handleConsentChange">
							<a-radio :value="true">{{$t('servcie_price_list.footer.consent.agree')}}</a-radio>
							<a-radio :value="false">{{$t('servcie_price_list.footer.consent.disagree')}}</a-radio>
						</a-radio-group>
					</div>
					<div class="signature">
						<div>
							<div v-if="!signData.customer.img" id="customer" class="sign-area">{{ $t('onfield_cost_estimate.footer.signature') }}</div>
							<div v-else class="sign-area">
								<img :src="signData.customer.img" class="signature-img">
							</div>
							<div v-if="!signData.customer.name" id="customerName" class="signature-item" v-html="$t('service_price_list.footer.name')"/>
							<div v-else class="signature-item" v-html="`(${signData.customer.name})`"/>
							<div class="signature-item" v-html="$t('service_price_list.footer.signature.customer')" />
							<div class="sign-button">
								<a-button id="customerBt" @click="openSignModal($refs.customerRef)"> {{ $t('action.sign') }} </a-button>
							</div>
						</div>
						<div>
							<div v-if="!signData.quoteBy.img" id="quoteBy" class="sign-area">{{ $t('onfield_cost_estimate.footer.signature') }}</div>
							<div v-else class="sign-area">
								<img :src="signData.quoteBy.img" class="signature-img">
							</div>
							<div v-if="!signData.quoteBy.name" id="quoteByName" class="signature-item" v-html="$t('service_price_list.footer.name')" />
							<div v-else class="signature-item" v-html="(`(${signData.quoteBy.name})`)"/>
							<div class="signature-item" v-html="$t('service_price_list.footer.signature.quote_by')" />
							<div class="sign-button">
								<a-button id="quoteByBt" @click="openSignModal($refs.quoteByRef)"> {{ $t('action.sign') }} </a-button>
							</div>
						</div>
						<div>
							<div v-if="!signData.approver.img" id="approver" class="sign-area">{{ $t('onfield_cost_estimate.footer.signature') }}</div>
							<div v-else class="sign-area">
								<img :src="signData.approver.img" class="signature-img">
							</div>
							<div v-if="!signData.approver.name" id="approverName" class="signature-item" v-html="$t('service_price_list.footer.name')" />
							<div v-else class="signature-item" v-html="(`(${signData.approver.name})`)"/>
							<div class="signature-item" v-html="$t('service_price_list.footer.signature.approve_by')" />
							<div class="sign-button">
								<a-button id="approverBt" @click="openSignModal($refs.approverRef)"> {{ $t('action.sign') }} </a-button>
							</div>
						</div>
					</div>
					<div>
						<div>
							<div class="page-divider" />
							<div style="page-break-before: always" align="center">
								<span style="visibility: hidden">-</span>
							</div>
						</div>
						<div class="consent-form-header">
							<div class="consent-form-title" >
								<div v-html="$t('service_price_list.consent.form.title')"/>
							</div>
						</div>
						<div>
							<div v-html="$t('service_price_list.consent.form', { customer: signData.customerConsent.name ? signData.customerConsent.name : '...................' })"/>
							<div class="signature-consent">
								<div>
								<div v-if="!signData.customerConsent.img" id="customerConsent">{{$t('onfield_cost_estimate.consent.signature.with.description')}}</div>
								<div v-else style="display: inline">
									{{$t('onfield_cost_estimate.consent.description')}}
									<img :src="signData.customerConsent.img" class="signature-img">
								</div>
								<div v-if="!signData.customerConsent.name" id="approverName" class="signature-item" v-html="$t('service_price_list.consent.name')" />
								<div v-else class="signature-item" v-html="(`(${signData.customerConsent.name})`)"/>
								<div v-if="!signData.customerConsent.signDate" class="signature-item" v-html="$t('service_price_list.consent.date')"/>
								<div v-else class="signature-item">{{$t('onfield_cost_estimate.sign_date', { date: signData.customerConsent.signDate }) }}</div>
								<div class="sign-button">
									<a-button id="approverBt" @click="openSignModal($refs.customerConsentRef)"> {{ $t('action.sign') }} </a-button>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="next-button">
					<a-button
						class="page-action-left "
						type="danger"
						icon="left-circle"
						@click="handleRollBack">
						{{$t('action.rollback.page')}}
					</a-button>
					<a-button
						class="page-action-right"
						type="primary"
						icon="right-circle"
						@click="handleSave">
						{{$t('action.next.page')}}
					</a-button>
				</div>
				<SignModal ref="customerRef"  @save="handleSign($event, 'customerRef')" />
				<SignModal ref="quoteByRef"  @save="handleSign($event, 'quoteByRef')" />
				<SignModal ref="approverRef" @save="handleSign($event, 'approverRef')" />
				<SignModal ref="customerConsentRef" @save="handleSign($event, 'customerConsentRef')" />
			</a-spin>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PhoneLink from '@components/common/PhoneLink.vue'
import { Table, Radio } from 'ant-design-vue'
import axios from 'axios'
import ApiError from '@utils/errors/ApiError'
import SignModal from '@components/common/SignModal.vue'
import PageMixin from '@mixins/PageMixin.vue'
import ServiceObjectMixin from '@mixins/service/ServiceObjectMixin.vue'
import {copyDeep} from "@utils/objectUtil"
import {claimInfo} from "@utils/claimUtil"
import {
					CLAIM_STATUS,
					PAYMENT_STATUS,
					getPartsByTypeService,
					calculateTechnicianFeeByTotal,
					calculateVatDisplayNumber,
					formatDisplayNumber
			} from "@utils/serviceUtil"


function _defaultPayment() {
	return {
		part_fee : 0 ,
		service_fee : 0 ,
		extra_service_fee : 0,
		technician_fee : 0,
		discount_fee : 0,
		approved_claimed_fee : 0,
		total_fee_exclude_discount : 0,
		total_fee : 0,
		remark : null,
		status : PAYMENT_STATUS.STATUS_NEW,
		completed_date : null,
		payment_remark : null,
	}
}

export default {
	components: {
		PhoneLink,
		'a-table': Table,
		'a-table-column': Table.Column,
		"a-radio" : Radio,
		"a-radio-group" : Radio.Group,
		SignModal
	},
	mixins : [PageMixin, ServiceObjectMixin],
	props: {
		service: {
			type: null,
			default: () => [],
		},
	},
	data() {
		return {
			totalPriceWithClaimApproved: 0,
			totalPrice: 0,
			technicianFeeWithClaimApproved: 0,
			technicianFee: 0,
			visible: true,
			serviceNumber: '',
			jobRepair: null,
			repairPartChanges: [],
			items: [],
			printDate: null,
			hasClaimPrice: false,
			loading: false,
			claimStatus: '',
			remark: '',
			claimRequest : {} ,
			signData: {
				customer: {
					img: null,
					name: null
				},
				inspector: {
					img: null,
					name: null
				},
				quoteBy: {
					img: null,
					name: null
				},
				approver: {
					img: null,
					name: null
				},
				customerConsent: {
					img: null,
					name: null,
					signDate: null
				}
			},
			repairParts: [],
			paymentValue : _defaultPayment() ,
			payment: {},
			agreement: false,
			totalPriceBeforeVat: 0,
			totalVat: 0,
			totalPriceAfterVat: 0,
			totalPriceBeforeVatMin: 0,
			totalVatMin: 0,
			totalPriceAfterVatMin: 0
		}
	},

	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapState('drone', ['manHourPrice']),
		...mapGetters('drone', ['getPartsInfo']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
	},
	mounted() {
		this.serviceNumber = this.service.service_no
		this.fetchData()
	},
	methods: {
		handleConsentChange(event) {
			this.agreement = event.target.value
		},
		getPageItem(item, index, claims) {

			return {...item,
				key: 'service_table_' + index,
				sequence: index + 1,
				priceDisplay: formatDisplayNumber(item.price),
				pricePerUnitDisplay: formatDisplayNumber(item.pricePerUnit),
				claimInfo: claimInfo(this, item)
			}
		},
		hasClaim(repairParts) {
			return repairParts?.[0] ? repairParts.filter(repairPart => repairPart.num_request).length > 0	: false
		},
		async fetchData() {
			this.loading = true
			// 1. get repair parts
			await axios.get(`/api/services/${this.$route.params.id}/jobs`).then((response) => {
				this.repairParts = response.data.data.service_parts ? response.data.data.service_parts.repair : []
				this.repairPartChanges = this.repairParts ? this.getPartsInfo(this.repairParts) : []
				this.jobRepair = ('job_repair' in response.data.data) ? response.data.data.job_repair : {}

				if(Array.isArray(this.repairParts) && this.hasClaim(this.repairParts)) {
					// 2. get claim request
					return 	axios.get(`/api/services/${this.$route.params.id}/claim-request`)
				} else {
					this.buildPagesFromItems()
					this.recalculateClaim()
				}
			}).then((response) => {
				if(!response)
					return

				this.claimStatus = response.data.data.claim_request.status
				this.claimRequest = response.data.data.claim_request
				this.buildPagesFromItems(response.data.data.service_parts.repair)
				this.recalculateClaim()
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
			try {
				this.payment = await axios.get("/api/services/"+this.$route.params.id+"/payment")
			} catch (error) {
				this.fetchError(error)
			}
		},
		openSignModal(ref) {
			ref.openSignModal()
		},
		handleSign(val, currentSigner) {
			switch (currentSigner) {
				case 'customerRef':
					this.signData.customer.img = val.img
					this.signData.customer.name = val.name
					break
				case 'quoteByRef':
					this.signData.quoteBy.img = val.img
					this.signData.quoteBy.name = val.name
					break
				case 'approverRef':
					this.signData.approver.img = val.img
					this.signData.approver.name = val.name
					break
				case 'customerConsentRef':
					this.signData.customerConsent.img = val.img
					this.signData.customerConsent.name = val.name
					this.signData.customerConsent.signDate = this.$dayjs().format("DD/MM/YYYY")
					break
			}
		},
		buildPagesFromItems(claims = []) {
			// combine repaire and normal part change list
			const itemList = this.repairPartChanges

			// append last element to be a dummy for the total fee row
			itemList.push({
				partSku: '-',
				partNumChange: 1,
				feePerUnit: 0,
				pricePerUnit: 0,
				description: '-',
				canClaim: false,
				sendForClaim: false
			})

			// transform the data
			this.items = itemList.map((item, index) => this.getPageItem(item, index, claims))
		},
		recalculateClaim() {
			const items = this.items.map((p) => p)
			// remove last item (servcie fee)
			items.splice(-1)

			const partsWithoutServ = getPartsByTypeService(items)
			const sumTotalTime = partsWithoutServ.reduce((acc, i) => acc + i.totalTime, 0)
			const sumFinalTime = partsWithoutServ.reduce((acc, i) => acc + i.finalTime, 0)

			this.technicianFee = calculateTechnicianFeeByTotal(sumTotalTime, this.manHourPrice)
			this.technicianFeeWithClaimApproved = calculateTechnicianFeeByTotal(sumFinalTime, this.manHourPrice)

			this.totalPrice = items.reduce((acc, i) => acc + i.price, 0) + this.technicianFee

			this.totalPriceWithClaimApproved = items.reduce((acc, i) => acc + i.priceWithClaimRequest, 0) + this.technicianFeeWithClaimApproved

			this.hasClaimPrice = this.totalPrice != this.totalPriceWithClaimApproved

			this.totalPriceBeforeVat = calculateVatDisplayNumber(this.totalPrice)
			this.totalVat = calculateVatDisplayNumber(this.totalPrice, true)
			this.totalPriceAfterVat = formatDisplayNumber(this.totalPrice)
			this.totalPriceBeforeVatMin = calculateVatDisplayNumber(this.totalPriceWithClaimApproved)
			this.totalVatMin =  calculateVatDisplayNumber(this.totalPriceWithClaimApproved, true)
			this.totalPriceAfterVatMin = formatDisplayNumber(this.totalPriceWithClaimApproved)

			// update last page item for total service fee
			const lastItem = this.items[this.items.length - 1]

			this.items[this.items.length - 1] = {
				...lastItem,
				pricePerUnitDisplay: '-',
				priceDisplay: formatDisplayNumber(this.technicianFee).concat(this.hasClaimPrice ? ` (${formatDisplayNumber(this.technicianFeeWithClaimApproved)})` : ''),
				description: this.$t('service_cost_estimate.total_technician_fee').concat(this.hasClaimPrice ? this.$t('service_cost_estimate.total_technician_fee_with_claim') : ''),
			}
		},
		dumpPriceListData() {
			return {
				renderData: {
					printDate: new Date().toLocaleDateString() + ': ' + new Date().toLocaleTimeString(),
					serviceCreatedDate: this.service.open_date,
					companyData: this.displayCompany,
					serviceNumber: this.service.service_no,
					droneSerial: this.service.droneData.drone.serial_no,
					dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
					droneModelName: this.service.droneData.model.name,
					customerName: this.service.customer_name,
					customerPhone: this.service.customer_phone,
					customerProvince: this.service.customer_province,
					remark: this.remark,
					technician: this.service.technicianData ? this.service.technicianData.user.full_name : this.$t('onfield_cost_estimate.undefined.technician'),
					reporter: this.service.openByData.user.full_name,
					signData: this.signData,
					items: this.items.map((item, index) => {
						return {
							index: index,
							sku: item.partSku,
							description: item.description,
							num: item.partNumChange,
							unitPrice: item.pricePerUnitDisplay,
							totalPrice: item.priceDisplay,
							claim: item.claimInfo
						}
					}),
					totalPriceBeforeVat: this.totalPriceBeforeVat,
					totalVat: this.totalVat,
					totalPriceAfterVat: this.totalPriceAfterVat,
					needPriceRange: this.hasClaimPrice,
					totalPriceBeforeVatMin: this.totalPriceBeforeVatMin,
					totalVatMin: this.totalVatMin,
					totalPriceAfterVatMin: this.totalPriceAfterVatMin,
					agreement: this.agreement,
				},
				module: 'service'
			}
		},
		dumpConsentData() {
			return {
				renderData: {
					printDate: new Date().toLocaleDateString() + ': ' + new Date().toLocaleTimeString(),
					signData: {
						customer: {
							name: this.signData.customerConsent.name,
							img: this.signData.customerConsent.img,
							signDate: this.signData.customerConsent.signDate,
						}
					}
				},
				module: 'service'
			}
		},
		dumpPaymentData() {
			const paymentForm = copyDeep(this.paymentValue)
			const formData = {}

			formData.payment = { id : this.payment.id, ...paymentForm}
			return formData
		},
		snapPaymentData() {
			const items = this.items.map((p) => p)
			return {
				part_fee: getPartsByTypeService(items).reduce((acc, i) => acc + (i.price ? i.price : 0), 0),
				service_fee:  getPartsByTypeService(items, true).reduce((acc, i) => acc + (i.price ? i.price : 0), 0),
				technician_fee: this.technicianFee,
				total_fee: this.totalPrice - (this.paymentValue.discount_fee + this.paymentValue.approved_claimed_fee),
				total_fee_exclude_discount: this.totalPrice,
			}
		},
		updateData() {
			this.loading = true

			// 1. create and upload documents to s3
			const estimationFormReq = axios.post(`/api/services/${this.service.id}/onfield-cost-estimation-form-upload`, this.dumpPriceListData())
			const consentFormReq = axios.post(`/api/services/${this.service.id}/onfield-consent-form-upload`, this.dumpConsentData())
			const requests = [estimationFormReq, consentFormReq]

			Promise.all([estimationFormReq, consentFormReq]).then((responses) => {
				// 2. attach the documents to service job
				const formData = {
					job_repair: this.jobRepair,
					service_files: responses.map((res, index) => {
						return {
							upload_type: index === requests.indexOf(estimationFormReq) ? 'service-price-list' : 'service-consent-file',
							files: [{
								mode: 'add',
								file_key: res.data.file.file_key,
								file_type: res.data.file.type
							}]
						}
					})
				}

				return axios.put(`/api/services/${this.service.id}/jobs`,formData)
			})
			.then((res) => {
				if (this.hasClaim(this.repairParts)) {
					const formData = {
						claim_request : {
							id: this.claimRequest.id,
						},
						next_status: CLAIM_STATUS.STATUS_REQUEST,
					}
					return axios.post(`/api/services/${this.service.id}/claim-request/update-status`,formData)
				}
			})
			.then((res) => {
				// 3. update onfield state to pre-delivery
				return axios.post(`/api/services/${this.service.id}/update-onfield-state`,{state: 'pre_delivery'})
			})
			.then((res) => {
				const formData = this.dumpPaymentData()
				formData.payment = {...formData.payment, ...this.snapPaymentData()}
				return axios.put(`/api/services/${this.$route.params.id}/payment`,formData)
			})
			.then((res) => {
				// 4. goto next page
				this.agreement ? this.$router.push({ name: 'onfield/pre_delivery', params: { id: this.service.id }}) : this.cancelService()
				this.$message.success(this.$t('onfield_cost_estimate.success'))
			})
			.catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this, error))
			})
			.finally(() => {
				this.loading = false
			})
		},
		async cancelService() {
			this.loading = true
			try {
				await axios.put(`/api/services/${this.service.id}/jobs`, {
					job_repair: {
						repair_result: 'fail',
						repair_result_reason: null,
					}
				})
				await axios.post(`/api/services/${this.service.id}/update-status/cancel`, {reason: 'ลูกค้าไม่ประสงค์ซ่อม' })
				this.$router.push({ name: 'onfield/cancel', params: { id: this.service.id } })
			} catch (error) {
				this.fetchError(error)
			} finally {
				this.loading = false
			}
		},
		handleSave() {
			if ((!this.signData.customer.img || !this.signData.quoteBy.img || !this.signData.customerConsent.img) && this.agreement) {
				this.$message.error(this.$t('onfield_cost_estimate.sign_warning'))
				return
			}

			this.$confirm({
				title: this.$t('onfield_cost_estimate.action.update.confirm.title'),
				content: this.$t('onfield_cost_estimate.action.update.confirm.message'),
				okText: this.$t('common.ok'),
				cancelText: this.$t('common.cancel'),
				maskClosable: true,
				onOk: () => this.updateData()
			})
		},
		handleRollBack() {
			this.$confirm({
				title: this.$t('cost_estimate.action.rollback.confirm.title'),
				content: this.$t('cost_estimate.action.rollback.confirm.message'),
				okText: this.$t('common.ok'),
				cancelText: this.$t('common.cancel'),
				maskClosable: true,
				onOk: async () => {
					try {
						await axios.post(`/api/services/${this.service.id}/update-onfield-state`,{state: 'service_job'})
						this.$router.push({ name: 'onfield/service_job', params: { id: this.service.id } })
						this.$message.success(this.$t('onfield_cost_estimate.success'))
					 } catch (error){
						this.$message.error(ApiError.getDisplayErrorMessage(this, error))
					 }
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.main-header {
	text-align: center;
}
.consent-group {
	display: grid;
}
.background {
	background : white;

	.mobile & {
		padding : 12px 16px;
	}
}
.page-loading {
	min-height: 300px;

	.ant-spin-blur {
		opacity: 0.4;
	}
	.ant-spin-dot i {
		background-color: @info-color;
	}
	.ant-spin-text {
		font-size: 2em;
		font-family: @font-family-title;
		margin-top: 14px;
		color: @info-color;
	}
}
.consent-form-header {
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
}
.consent-form-title {
	text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #14837B;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 16px;
	border-radius: @border-radius-base;
	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			flex-grow: 1;
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.total-section {
	display: flex;
	flex-direction: row-reverse;
	margin-top: 0px;
	margin-bottom: 16px;

	.total-section-column {
		display: flex;
		flex-direction: column;
		flex-basis: 60%;
		margin-top: 8px;
		margin-bottom: 0px;

		.total-section-row {
			display: flex;
			flex-direction: row-reverse;
			justify-content: right;
			align-items: right;
			margin-top: 8px;

			.total-section-description {
				display: flex;
				flex-basis: 50%;
				position: relative;
				justify-content: right;
				align-items: right;
				margin-right: 8px;
			}

			.total-section-dash {
				display: flex;
				flex-basis: 2%;
				position: relative;
				justify-content: right;
				align-items: right;
				margin-right: 8px;
			}

			.total-section-price-claim {
				display: flex;
				flex-basis: 20%;
				position: relative;
				justify-content: right;
				align-items: right;
				margin-right: 8px;
			}

			.total-section-price {
				display: flex;
				flex-basis: 20%;
				position: relative;
				justify-content: left;
				align-items: left;
				margin-right: 8px;
			}

			.total-section-unit {
				display: flex;
				flex-basis: 8%;
				position: relative;
				justify-content: left;
				align-items: left;
				margin-right: 8px;
			}
		}
	}
}
.sign-area {
		display: block;
		align-items: center;
		width: 123.16px;
		height: 40px;
}
.signature-consent {
	margin-left: auto;
	text-align: center;
	display: block;
	width: 30%;
}
.signature {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;
	.signature-item {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;
	}
	.sign-button {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.signature-img {
	display: block;
	width: 100%;
}
.page-divider {
	display: flex;
	padding: 12px 16px;
	border-bottom: 1px dashed @primary-color;
	position: relative;
}
.page-action-left {
	float: left;
	margin-bottom : 8px;
}
.page-action-right {
	float: right;
	margin-bottom : 8px;
}
</style>
